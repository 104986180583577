.site-header {
  &__menu {
    .site-header {
      &__menu-list--desktop {
        .menu {
          &__item--lvl-1 {
            margin: 0px;
          }
        }
      }
    }
  }
}
