.section-customer-service-contact-us {
  .cs-page__content {
    float: #{$ldirection};
  }
}

.contact-us {
  &__salesforce {
    max-width: 800px;
    margin: 15px auto;
    select,
    input[type='text'],
    input[type='email'] {
      @include swap_direction(padding, 0 12px 0 14px);
      border: 1px solid $color-grey;
      height: 32px;
      line-height: normal;
      width: 95%;
      @include breakpoint($portrait-up) {
        height: 34px;
      }
    }
    input[type='checkbox'] {
      display: none;
    }
    fieldset {
      border: none;
      margin: 0;
      padding: 0;
    }
    .label-error {
      margin-bottom: 10px;
      color: $color-red;
    }
    p {
      font-size: 18px;
      margin: 1em 0;
      font-weight: normal;
      padding: 0;
      color: $color-black;
      &.contact-us {
        &__salesforce-right-content--title {
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    &-title {
      font-size: 20px;
      text-align: #{$ldirection};
    }
    &-subtitle {
      font-size: 20px;
      text-align: #{$ldirection};
    }
    &-left-content {
      float: #{$ldirection};
      select {
        @include breakpoint($portrait-up) {
          width: 295px;
        }
      }
    }
    &-right-content {
      float: #{$ldirection};
      margin-bottom: 20px;
    }
    .form-item {
      width: 100%;
      margin-bottom: 24px;
      float: #{$ldirection};
      @include breakpoint($portrait-up) {
        width: 50%;
      }
      label {
        display: block;
        @include breakpoint($portrait-up) {
          display: inline-block;
        }
        &.error {
          margin-top: 0;
          color: $color-red;
          & + input,
          & + select,
          & + textarea {
            border-color: $color-red;
          }
        }
      }
      &.contact-form {
        &__sub-question-type,
        &__question-type {
          width: 100%;
          margin-bottom: 0;
          padding: 8px 0;
          label {
            display: inline-block;
            word-break: break-word;
            width: 93%;
          }
        }
        &__question-type {
          display: inline-block;
        }
      }
      input[type='radio'] {
        width: auto;
        vertical-align: top;
      }
      &__legaltext {
        font-size: 12px;
        width: 100%;
      }
      &__newsletter {
        font-size: 12px;
        width: 100%;
      }
      &__description {
        max-width: 460px;
        width: 100%;
        @include breakpoint($portrait-up) {
          width: 70%;
        }
        textarea {
          width: 100%;
          height: 120px;
          @include breakpoint($portrait-up) {
            width: 100%;
          }
        }
      }
      &__required {
        font-size: 12px;
        padding-bottom: 15px;
      }
    }
    .form-submit {
      display: block;
      clear: #{$ldirection};
    }
  }
  label {
    font-size: 12px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }
}

.contact-form {
  &__section {
    &--question-types {
      &-titles {
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 10px;
      }
    }
  }
  &__sub-question-types {
    padding: 15px 0 20px;
    span {
      font-weight: bold;
    }
  }
}
