.footer {
  .site-email-signup {
    &__field {
      float: #{$ldirection};
      [dir='rtl'] & {
        float: #{$rdirection};
      }
      margin-right: 5px;
    }
    &__title {
      text-align: left;
    }
  }
  .site-footer-social-links {
    margin-top: 30px;
    &__header {
      margin-top: 13px;
    }
  }
}
