.product-brief {
  &__name {
    text-align: center;
  }
  &__cta {
    &-container {
      &--right {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.product-quickshop {
  .product {
    &-size {
      display: none;
    }
    &-sku {
      &-price {
        display: none;
      }
    }
  }
  &__content {
    .flag {
      display: none;
    }
  }
}

#colorbox {
  outline: 0 !important;
}

.product-flag {
  &__container {
    display: inline-block;
  }
}

.product-full {
  .product {
    &-size,
    &-sku-size {
      display: none;
    }
  }
  &__cta-section--mobile,
  &__name {
    text-align: center;
  }
}

.spp {
  &__container {
    .after-product,
    .spp-cross-sells {
      display: none;
    }
  }
  &__container-contents {
    display: none;
  }
}

.button {
  &--transparent {
    background: $color-black;
    border: 0;
    color: $color-white;
  }
}

.offers-email-signup {
  input[type='email'] {
    margin-inline-start: 0;
  }
}

@media only screen and (min-width: $large-up) {
  .slick {
    &-prev {
      position: absolute;
      z-index: 2;
      inset-inline-start: 20px;
    }
    &-next {
      position: absolute;
      z-index: 2;
      inset-inline-end: 50px;
      &::before {
        right: auto;
      }
    }
  }
  .site-content {
    max-width: $max-width-cr21;
  }
  .content {
    .basic-carousel-formatter {
      &--full-width {
        position: relative;
        max-width: $max-width-cr21;
        margin: 0 auto;
        left: auto;
        right: auto;
        width: auto;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .field-content {
    padding: 0 15px;
  }
  .site-content {
    padding-top: 75px;
  }
  .header-nav-section__section {
    .header-nav-section {
      &__section-label {
        &--text:hover {
          color: $color-white;
        }
      }
      &__section {
        &__section-label:hover {
          color: $color-white;
        }
      }
    }
  }
  .gnav-search {
    &__block {
      display: none;
    }
  }
  .content {
    .site-header-formatter {
      &__bar {
        padding: 10px 0;
      }
      height: 70px;
    }
  }
  body {
    .site-header {
      position: fixed;
    }
  }
  .basic-grid {
    &__item {
      .content-block-large,
      .content-block-large__image,
      .content-block-large__image img {
        height: auto;
      }
    }
  }
}

@media only screen and (min-width: $landscape-up) {
  .header-nav-section__section:not(.header-nav-section__section ~ *) {
    padding-inline: 0 20px;
  }
  .header-nav-section__section:not(:has(~ .header-nav-section__section)) {
    padding-inline: 20px 0;
  }
  .header-nav-section__section {
    padding: 0 20px;
  }
  body {
    .site-header {
      position: relative;
    }
  }
  .field-content {
    padding: 0 20px;
  }
}

.site-header-formatter__sections {
  .header-nav-section__trigger {
    &:checked {
      & ~ .header-nav-section__section {
        .header-nav-section__section-label--text {
          text-align: left;
        }
      }
    }
  }
}

@media only screen and (min-width: $landscape-up) and (max-width: 1680px) {
  .site-header-formatter {
    &__sections {
      .header-nav-section {
        &__section-label {
          &--text {
            font-size: 1.4vw;
          }
        }
      }
    }
  }
}
